/* istanbul ignore file */

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'Home',
            },
            component: () => import(/* webpackChunkName: "Home" */ '../views/IHome.vue')
        },
        {
            path: '/workspace',
            name: 'workspace',
            meta: {
                title: 'Workspace',
            },
            component: () => import(/* webpackChunkName: "Workspace" */ '../views/IWorkspace.vue'),
            children: [
                {
                    path: 'department-news/:articleSlug',
                    name: 'workspace.department-news',
                    component: () => import(/* webpackChunkName: "ArticleDetail" */ '../views/IArticleDetail.vue'),
                },
            ]
        },
        {
            path: '/imt-happenings',
            name: 'imt-happenings',
            meta: {
                title: 'IMT Happenings',
            },
            component: () => import(/* webpackChunkName: "IMTHappenings" */ '../views/IIMTHappenings.vue'),
            children: [
                {
                    path: ':articleSlug',
                    name: 'imt-happenings.article',
                    component: () => import(/* webpackChunkName: "ArticleDetail" */ '../views/IArticleDetail.vue'),
                },
                {
                    path: '/calendar',
                    name: 'imt-happenings.calendar',
                    meta: {
                        title: 'Company Calendar',
                    },
                    component: () => import(/* webpackChunkName: "CompanyCalendar" */ '../views/ICompanyCalendar.vue'),
                },
            ]
        },
        {
            path: '/human-resources',
            name: 'human-resources',
            meta: {
                title: 'Human Resources',
            },
            component: () => import(/* webpackChunkName: "HumanResources" */ '../views/IHumanResources.vue'),
            children: [
                {
                    path: 'updates/:articleSlug',
                    name: 'human-resources.article',
                    component: () => import(/* webpackChunkName: "ArticleDetail" */ '../views/IArticleDetail.vue'),
                },
                {
                    path: 'page/:pageSlug',
                    name: 'human-resources.page',
                    component: () => import(/* webpackChunkName: "GeneralPage" */ '../views/IGeneralPage.vue')
                }
            ]
        },
        {
            path: '/cultural-roadmap/page/:pageSlug',
            name: 'cultural-roadmap',
            meta: {
                title: 'Cultural Roadmap',
            },
            component: () => import(/* webpackChunkName: "GeneralPage" */ '../views/IGeneralPage.vue'),
        },
        {
            path: '/people-and-culture',
            name: 'people-and-culture',
            meta: {
                title: 'People and Culture',
            },
            component: () => import(/* webpackChunkName: "PeopleAndCulture" */ '../views/PeopleAndCulture.vue'),
            children: [
                {
                    path: 'page/:pageSlug',
                    name: 'people-and-culture.page',
                    component: () => import(/* webpackChunkName: "GeneralPage" */ '../views/IGeneralPage.vue')
                },
                {
                    path: 'employee-announcements/:articleSlug',
                    name: 'people-and-culture.article',
                    component: () => import(/* webpackChunkName: "ArticleDetail" */ '../views/IArticleDetail.vue')
                },
                {
                    path: '/classifieds',
                    name: 'people-and-culture.classifieds',
                    meta: {
                        title: 'Classifieds',
                    },
                    component: () => import(/* webpackChunkName: "ClassifiedsAndFundraisers" */ '../views/IClassifieds.vue'),
                },
                {
                    path: '/employee-directory',
                    name: 'people-and-culture.employee-directory',
                    meta: {
                        title: 'Employee Directory',
                    },
                    component: () => import(/* webpackChunkName: "IEmployeeDirectory" */ '../views/IEmployeeDirectory.vue'),
                },
                {
                    path: '/employee-directory/my-profile',
                    name: 'people-and-culture.employee-directory.my-profile',
                    component: () => import(/* webpackChunkName: "IEmployeeDirectoryProfile" */ '../views/IEmployeeDirectoryProfile.vue'),
                    children: [
                        {
                            path: 'favorites',
                            name: 'people-and-culture.employee-directory.my-profile.favorites',
                            component: () => import(/* webpackChunkName: "IEmployeeDirectoryProfile" */ '../views/IEmployeeDirectoryProfile.vue'),
                        }
                    ]
                },
                {
                    path: '/employee-directory/:paylocityID',
                    name: 'people-and-culture.employee-directory.profile',
                    component: () => import(/* webpackChunkName: "IEmployeeDirectoryProfile" */ '../views/IEmployeeDirectoryProfile.vue'),
                },
            ]
        },
        {
            path: '/search',
            name: 'search',
            meta: {
                title: 'Search',
            },
            component: () => import(/* webpackChunkName: "IntranetSearch" */ '../views/ISearchResults.vue')
        },
        {
            path: '*',
            name: 'not-found',
            meta: {
                title: 'Not Found',
            },
            component: () => import(/* webpackChunkName: "NotFound" */ '../components/INotFound.vue')
        }
    ],

    router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior() {
            return {x: 0, y: 0};
        },
    });

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = 'IMT Intranet - ' + `${to.meta.title}`;
    }

    next();
});

export default router;
